<template>
  <b-form-group
    ref="image_url_1"
    :label="$t('img Upload.Upload Image')"
    prop="image_url_1"
    class="mt-1"
  >
    <b-form-file
      type="file"
      :placeholder="$t('img Upload.No File Choesen')"
      name="image"
      accept="image/*"
      @change="(e) => setImage(e, 'image1')"
    />

    <small style="display: block">
      {{ $t("img Upload.Upload a image of") }} {{ width }}(W) x {{ height }}(H)
      pixel</small
    >

    <div
      v-show="imgSrc['image1']"
      style="
        border: 1px solid gray;
        display: inline-block;
        max-height: 500px;
        max-width: 500px;
      "
    >
      <vue-cropper
        v-if="!value"
        ref="cropper"
        :guides="true"
        :view-mode="1"
        drag-mode="crop"
        :min-container-width="250"
        :min-container-height="180"
        :max-container-width="1000"
        :max-container-height="500"
        :background="true"
        :rotatable="false"
        :zoomOnWheel="true"
        :minCropBoxWidth="width"
        :minCropBoxHeight="height"
        :cropBoxResizable="true"
        :src="imgSrc['image1']"
        alt="Source Image"
        :img-style="{ width: width, height: height }"
        style="max-height: 500px"
        :aspect-ratio="width / height"
      ></vue-cropper>
    </div>

    <br v-if="!showUpload.upload1" />
    <div style="display: block">
      <img
        v-show="value"
        :height="height > 500 ? 300 : this.h"
        :width="width > 500 ? 400 : this.w"
        :src="baseurl + value"
        shape="square"
      />
      <span v-if="this.w">width: {{ this.w }}</span>
      <span v-if="this.h">height: {{ this.h }}</span>
    </div>
    <br />
    <br v-if="img_blob" />
    <b-button
      v-if="value"
      @click="
        (e) => {
          handleFileRemove(e, { path: 'image_url_1' });
        }
      "
    >
      {{ $t("img Upload.Delete") }}</b-button
    >
    &nbsp;
    <b-button
      v-if="!value && imgSrc['image1']"
      @click="
        (e) => {
          cropImage(e, 'image1');
        }
      "
      >{{ $t("img Upload.Crop") }}</b-button
    >
    &nbsp;
    <b-button
      v-if="img_blob && !value"
      @click="onUploadFile(img_blob, { path: 'image_url_1' })"
      >{{ $t("img Upload.Upload") }}</b-button
    >
  </b-form-group>
</template>

<script>
import { BFormGroup, BButton, BFormFile } from "bootstrap-vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import MasterServices from "@/apiServices/MasterServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ImageUploadWithCropper",
  components: { VueCropper, BFormGroup, BButton, BFormFile },
  props: ["width", "height", "value", "imagetype", "label"],

  computed: {
    baseurl: () => process.env.VUE_APP_IMAGEURL,
  },

  data: function () {
    return {
      imgSrc: { image1: null },
      showUpload: { upload1: false, upload2: false },
      cropImg: { image1: null },
      img_blob: null,
      w: null,
      h: null,
    };
  },

  methods: {
    setImageURL(url) {
      this.$emit("input", url);
    },
    async setImage(e) {
      const file = e.target.files[0];

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.setImageURL(null);
          this.imgSrc.image1 = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader Api not supported");
      }
    },
    cropImage(e) {
      this.cropImg.image1 = this.$refs.cropper
        .getCroppedCanvas({
          width: this.width,
          height: this.height,
        })
        .toDataURL();
      this.w = this.width;
      this.h = this.height;

      var block = this.cropImg.image1.split(";");

      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"

      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

      // Convert it to a blob to upload
      var blob1 = this.b64toBlob(realData, contentType);

      this.img_blob = blob1;
    },
    async onUploadFile(event) {
      const file = event;
      this.showCrop = false;
      if (file) {
        const formData = new FormData();
        formData.append("image_type", this.imagetype);
        formData.append("image", file);
        // const uploadLoader = this.$loading.show();
        MasterServices.commonFileUpload(formData)
          .then((response) => {
            this.setImageURL(response.data.data.image_path);
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "File Successfully Uploaded",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.showUpload.upload1 = true;
            this.img_blob = null
          })
          .catch((err) => {
            console.log(err);
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Error uploading image",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          })
          .finally(() => {
            // uploadLoader.hide();
          });
      }
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    rotate() {
      this.$refs.cropper2.rotate(90);
    },
    handleFileRemove(event) {
      MasterServices.commonFileRemove({
        image_type: this.imagetype,
        filename: this.value,
      })
        .then((response) => {
          this.setImageURL(null);
          this.showUpload.upload1 = false;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error in removing image",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
  },
};
</script>
