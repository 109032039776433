<template>
	<b-card>
		<!-- form -->
		<validation-observer ref="recipeMainValidation">
			<b-form>
				<b-row>
					<b-col>
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="secondary"
							@click="$router.go(-1)"
						>
							<feather-icon icon="SkipBackIcon" />
							{{ $t("Common.Back") }}
						</b-button>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Name')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Name"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_name"
									:state="errors.length > 0 ? false : null"
									type="text"
									:placeholder="$t('RecipeMainMaster.Recipe Name')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Code')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Code"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_code"
									:state="errors.length > 0 ? false : null"
									type="text"
									:placeholder="$t('RecipeMainMaster.Recipe Code')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Type')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Type"
								rules="required"
							>
								<v-select
									:reduce="(mrecipe_type) => mrecipe_type.value"
									label="text"
									:placeholder="$t('RecipeMainMaster.Recipe Type')"
									:state="errors.length > 0 ? false : null"
									v-model="form.recipe_type"
									:options="recipe_typeList"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe SS ID')">
							<validation-provider
								#default="{ errors }"
								name="Recipe SS ID"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_ss_id"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Recipe SS ID')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Select Recipe Master')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Mater"
								rules="required"
							>
								<v-select
									:reduce="(mrecipe) => mrecipe.mrecipe_id"
									label="mrecipe_name"
									:placeholder="$t('RecipeMainMaster.Select Recipe Master')"
									:state="errors.length > 0 ? false : null"
									v-model="form.recipe_mrecipe_id"
									:options="$store.getters['food/getAllRecipeMaster']"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Language')">
							<validation-provider
								#default="{ errors }"
								name="Language"
								rules="required"
							>
								<v-select
									:reduce="(language) => language.language_id"
									label="language_name"
									:placeholder="$t('RecipeMainMaster.Language')"
									v-model="form.recipe_lang_id"
									:state="errors.length > 0 ? false : null"
									:options="$store.getters['masters/getAllLanguage']"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<!-- <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  :reduce="(category) => category.category_id"
                  label="category_name"
                  placeholder="Category Name"
                  v-model="form.recipe_category"
                  :state="errors.length > 0 ? false : null"
                  :options="$store.getters['masters/getAllCategory']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>-->
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Country')">
							<validation-provider
								#default="{ errors }"
								name="Country"
								rules="required"
							>
								<v-select
									:reduce="(country) => country.country_id"
									label="country_name"
									:placeholder="$t('RecipeMainMaster.Country')"
									v-model="form.recipe_country_id"
									:state="errors.length > 0 ? false : null"
									:options="$store.getters['masters/getAllCountry']"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Total Time')">
							<validation-provider
								#default="{ errors }"
								name="Total Time"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_total_time_mins"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Total Time')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Cook Time')">
							<validation-provider
								#default="{ errors }"
								name="Cook Time"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_cook_time_mins"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Cook Time')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Prep Time')">
							<validation-provider
								#default="{ errors }"
								name="Prep. Time"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_prep_time_mins"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Prep Time')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Portions')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Portions"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_portions"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Recipe Portions')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Calories')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Calories"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_calories"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Recipe Calories')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Carb Content')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Carb Content"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_carb_content"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Recipe Carb Content')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Fat Content')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Fat Content"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_fat_content"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Recipe Fat Content')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group
							:label="$t('RecipeMainMaster.Recipe Protein Content')"
						>
							<validation-provider
								#default="{ errors }"
								name="Recipe Protein Content"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_protein_content"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Recipe Protein Content')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Type 1')">
							<validation-provider
								#default="{ errors }"
								name="Recipe Type 1"
								rules="required"
							>
								<b-form-input
									v-model="form.recipe_type_1"
									:state="errors.length > 0 ? false : null"
									type="number"
									:placeholder="$t('RecipeMainMaster.Recipe Type 1')"
									:disabled="!$can('write', 'food')"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="4">
						<b-form-group :label="$t('RecipeMainMaster.Recipe Type 2')">
							<b-form-input
								v-model="form.recipe_type_2"
								type="number"
								:placeholder="$t('RecipeMainMaster.Recipe Type 2')"
								:disabled="!$can('write', 'food')"
							/>
						</b-form-group>
					</b-col>
					<b-row style="margin: auto">
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_veg"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Veg") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_lactose_friendly"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Lactose Friendly") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_gluten_friendly"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Gluten Friendly") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_peanut_friendly"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Peanut Friendly") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_chicken"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Chicken") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_turkey"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Turkey") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_pork"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Pork") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_lamb"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Lamb") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_beef"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Beef") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_fish"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Fish") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_egg"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Egg") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_legume"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Legume") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_milk_heavy"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Milk Heavy") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_carb_heavy"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Carb Heavy") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_nuts_heavy"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Nuts Heavy") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.recipe_is_veggie_heavy"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Veggie Heavy") }}</b>
								</div>
							</div>
						</b-col>
						<!-- Added By Priyanshu SIngh -->
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.is_meat"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Meat") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.is_red_meat"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Red Meat") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.is_other_meat"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Other Meat") }}</b>
								</div>
							</div>
						</b-col>
						<b-col cols="3" class="text-center mt-1">
							<div class="row">
								<div class="col-md-2">
									<b-form-checkbox
										name="check-button"
										switch
										inline
										v-model="form.is_vegan"
									></b-form-checkbox>
								</div>
								<div class="col-md-10 text-left">
									<b>{{ $t("RecipeMainMaster.Vegan") }}</b>
								</div>
							</div>
						</b-col>
						<!-- Added By Priyanshu SIngh -->
					</b-row>
					<br />
					<br />
					<br />
					<b-col cols="12">
						<image-upload-with-cropper
							v-model="form.recipe_image_file"
							:width="458"
							:height="241"
							imagetype="ingredients"
							label="Upload image"
						/>
					</b-col>

					<b-row style="margin: auto">
						<b-col cols="12">
							<h1>Steps</h1>
						</b-col>

						<br />

						<b-col cols="12">
							<b-form-group label="Number of Steps">
								<b-form-input
									v-model="form.recstep_step_num"
									type="number"
									:disabled="!$can('write', 'food')"
								/>
							</b-form-group>
						</b-col>

						<b-row
							style="margin: auto"
							v-for="(sub_step, substep_index) in form.sub_steps"
							:key="'substep_index_' + substep_index"
						>
							<b-col cols="12">
								<h2>Sub Step {{ substep_index + 1 }}</h2>
							</b-col>

							<b-col cols="6">
								<b-form-group label="Sub Step Name">
									<b-form-input
										:disabled="!$can('write', 'food')"
										v-model="sub_step.recstep_substep_name"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="6">
								<b-form-group label="Sub Step Number">
									<b-form-input
										v-model="sub_step.recstep_substep_num"
										type="number"
										:disabled="!$can('write', 'food')"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="12" style="padding-bottom: 20px">
								<b-row
									style="margin: auto; padding-bottom: 10px"
									v-for="(step, step_index) in sub_step.steps"
									:key="'step_index_' + step_index"
								>
									<b-col cols="12">
										<h2>Instruction {{ step_index + 1 }}</h2>
									</b-col>
									<b-col cols="6">
										<b-form-group label="Instructions Text">
											<b-form-textarea
												style="width: 100%"
												v-model="step.recstep_step_text"
												placeholder="Instrunctions Text"
												:disabled="!$can('write', 'food')"
											/>
										</b-form-group>
									</b-col>

									<b-col cols="6">
										<b-form-group label="Instructions Html">
											<b-form-textarea
												style="width: 100%"
												v-model="step.recstep_step_html"
												placeholder="Instrunctions Html"
												:disabled="!$can('write', 'food')"
											/>
										</b-form-group>
									</b-col>

									<b-col cols="6">
										<b-form-group label="Actions">
											<!-- {{step}} -->
											<!-- {{$store.getters['food/getAllFoodActions']}} -->
											<v-select
												multiple
												:reduce="(action) => action.langaction_id"
												label="langaction_name"
												v-model="step.recstep_substep_action_id"
												:options="$store.getters['food/getAllFoodActions']"
												:closeOnSelect="false"
												@input="
													getFoodIcons(
														step.recstep_substep_action_id,
														substep_index,
														step_index,
														form.sub_steps,
														'change'
													)
												"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="6">
										<b-form-group label="Instructions Icons">
											<!-- {{step.f_icons}} -->
											<v-select
												multiple
												:reduce="(icon) => icon.icon_id"
												label="icon_main_name"
												v-model="step.f_icons"
												:options="
													$store.getters['food/getAllFoodIcons']
														? $store.getters['food/getAllFoodIcons']
														: []
												"
												:closeOnSelect="false"
											>
												<template #selected-option="data">
													<img
														:src="FILESURL + 'icons/' + data.icon_file_name"
														size="16"
														class="align-middle mr-50"
														width="40"
														height="20"
													/>
													<span>{{ data.icon_main_name }}</span>
												</template>
												<template #option="data">
													<!-- <b-avatar
                            :src="FILESURL+'icons/'+ data.icon_file_name"
                            size="16"
                            class="align-middle mr-50"
                          />-->
													<img
														:src="FILESURL + 'icons/' + data.icon_file_name"
														size="16"
														width="40"
														height="20"
														class="align-middle mr-50"
													/>
													<span>{{ " " + data.icon_main_name }}</span>
												</template>
											</v-select>
											<!-- uploads/images/food/icons/ -->
										</b-form-group>
									</b-col>
									<b-col cols="12">
										<table
											class="
												table table-responsive-lg
												mt-3
												table-light table-bordered
												shadow
												p-3
												mb-5
												bg-white
												rounded
											"
										>
											<thead>
												<tr>
													<th>{{ $t("RecipeMainMaster.SN") }}</th>
													<th>{{ $t("RecipeMainMaster.Ingredient") }}</th>
													<th>{{ $t("RecipeMainMaster.Quantity") }}</th>
													<th>{{ $t("RecipeMainMaster.Unit") }}</th>
													<th class="plusicon">
														<b-button
															variant="primary"
															type="submit"
															@click.prevent="
																AddInstructionIngredient(
																	substep_index,
																	step_index
																)
															"
														>
															<feather-icon icon="PlusSquareIcon" />
														</b-button>
													</th>
												</tr>
											</thead>
											<tbody v-if="step.ingredients.length > 0">
												<tr
													v-for="(ins_ing, ins_ing_index) in step.ingredients"
													:key="'ins_ing_index' + ins_ing_index"
												>
													<td>{{ ins_ing_index + 1 }}.</td>
													<td>
														<validation-provider
															#default="{ errors }"
															name="Ingredient"
															rules="required"
														>
															<v-select
																:reduce="
																	(IngredientMain) =>
																		IngredientMain.ingredient_id
																"
																label="ingredient_name"
																placeholder="Select Ingredient"
																v-model="ins_ing.ingredient_id"
																:options="filteredIngredients"
																:disabled="!$can('write', 'food')"
															/>
															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</td>
													<td>
														<b-form-input
															v-model="ins_ing.units_quantity"
															type="number"
														/>
													</td>
													<td>
														<validation-provider
															#default="{ errors }"
															name="Unit"
															rules="required"
														>
															<v-select
																:reduce="
																	(AllLangUnit) => AllLangUnit.langunit_id
																"
																label="langunit_name"
																v-model="ins_ing.unit_id"
																:options="
																	$store.getters['masters/getAllLangUnit']
																"
																:disabled="!$can('write', 'masters')"
																placeholder="Select"
															/>
															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</td>
													<td>
														<b-button
															variant="primary"
															type="submit"
															@click.prevent="
																removeInstructionIngredient(
																	substep_index,
																	step_index,
																	ins_ing_index
																)
															"
														>
															<feather-icon icon="MinusSquareIcon" />
														</b-button>
													</td>
												</tr>
											</tbody>
										</table>
									</b-col>
									<br />
									<b-button
										@click="RemoveInstruction(substep_index, step_index)"
										>Remove Instruction</b-button
									>
									<br />
								</b-row>
							</b-col>
							<br />
							<b-col cols="12">
								<b-button @click="AddInstruction(substep_index)"
									>Add Instruction</b-button
								>
							</b-col>
							<b-col cols="12">
								<b-button @click="RemoveSubStep(substep_index)"
									>Remove Substep</b-button
								>
							</b-col>
						</b-row>
						<b-button @click="AddSubStep">Add Substep</b-button>
						<br />
					</b-row>
					<b-col md="12">
						<table
							class="
								table table-responsive-lg
								mt-3
								table-light table-bordered
								shadow
								p-3
								mb-5
								bg-white
								rounded
							"
						>
							<thead>
								<tr>
									<th>{{ $t("RecipeMainMaster.SN") }}</th>
									<th>{{ $t("RecipeMainMaster.Ingredient") }}</th>
									<th>{{ $t("RecipeMainMaster.Quantity") }}</th>
									<th>Plates Num</th>
									<th>{{ $t("RecipeMainMaster.Unit") }}</th>
									<th class="plusicon">
										<!-- {{ $t("RecipeMainMaster.Action") }} -->
										<b-button
											variant="primary"
											type="submit"
											@click.prevent="AddIngredient()"
										>
											<feather-icon icon="PlusSquareIcon" />
										</b-button>

										<!-- <feather-icon icon="PlusSquareIcon"
                    /></b-button>-->
									</th>
								</tr>
							</thead>
							<tbody v-if="recipe_ingredients.length > 0">
								<tr
									v-for="(item, index) in recipe_ingredients"
									:key="'recipe_ingredients' + index"
								>
									<td>{{ index + 1 }}.</td>
									<td>
										<v-select
											:reduce="(IngredientMain) => IngredientMain.ingredient_id"
											label="ingredient_name"
											placeholder="Select Ingredient"
											v-model="item.ingredient_id"
											:options="$store.getters['food/getAllIngredientMain']"
											:disabled="!$can('write', 'food')"
										/>
									</td>
									<td>
										<b-form-input v-model="item.units_quantity" type="number" />
									</td>
									<td>
										<b-form-input v-model="item.num_plates" type="number" />
									</td>
									<td>
										<v-select
											:reduce="(AllLangUnit) => AllLangUnit.langunit_id"
											label="langunit_name"
											v-model="item.unit_id"
											:options="$store.getters['masters/getAllLangUnit']"
											:disabled="!$can('write', 'masters')"
											placeholder="Select"
										/>
									</td>
									<td>
										<b-button
											variant="primary"
											type="submit"
											@click.prevent="removeIngredient(index)"
										>
											<feather-icon icon="MinusSquareIcon" />
										</b-button>
									</td>
								</tr>
							</tbody>
						</table>
					</b-col>
					<b-col cols="12" class="text-center mt-1">
						<b-button
							variant="primary"
							type="submit"
							@click.prevent="saveRecipeMain"
							>{{ $t("Common.Save") }}</b-button
						>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploadWithCropper from "@/views/components/ImageUploadWithCropper.vue";
import {
	BCard,
	BFormInput,
	BFormGroup,
	BForm,
	BRow,
	BCol,
	BButton,
	BCardText,
	BFormTextarea,
	BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import FoodServices from "@/apiServices/FoodServices";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
	name: "RecipeForm",
	directives: {
		Ripple,
	},
	components: {
		ImageUploadWithCropper,
		ValidationProvider,
		ValidationObserver,
		BCard,
		BCardText,
		BFormTextarea,
		vSelect,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
		BFormCheckbox,
	},
	data() {
		return {
			recipe_ingredients: [],
			recipeMasterLists: [],
			countryLists: [],
			languageLists: [],
			categoryLists: [],
			recipe_typeList: [
				{
					value: 1,
					text: "Lunch / Dinner",
				},
				{
					value: 2,
					text: "Breakfast Only",
				},
				{
					value: 3,
					text: "Snack Only",
				},
				{
					value: 4,
					text: "Breakfast / Snack",
				},
				{
					value: 5,
					text: "Any",
				},
			],
			form: {
				recipe_id: null,
				recipe_mrecipe_id: null,
				recipe_ss_id: "",
				recipe_lang_id: "",
				recipe_country_id: "",
				recipe_name: "",
				recipe_code: "",
				recipe_type: "",
				recipe_total_time_mins: "",
				recipe_cook_time_mins: "",
				recipe_prep_time_mins: "",
				recipe_portions: "",
				recipe_calories: "",
				recipe_carb_content: "",
				recipe_fat_content: "",
				recipe_protein_content: "",
				recipe_category: "",
				recipe_type_1: "",
				recipe_type_2: "",
				recipe_image_file: null,
				recipe_is_veg: false,
				recipe_is_lactose_friendly: false,
				recipe_is_gluten_friendly: false,
				recipe_is_peanut_friendly: false,
				recipe_is_chicken: false,
				recipe_is_turkey: false,
				recipe_is_pork: false,
				recipe_is_lamb: false,
				recipe_is_beef: false,
				recipe_is_fish: false,
				recipe_is_egg: false,
				recipe_is_legume: false,
				recipe_is_milk_heavy: false,
				recipe_is_carb_heavy: false,
				recipe_is_nuts_heavy: false,
				recipe_is_veggie_heavy: false,

				is_meat: false,
				is_red_meat: false,
				is_other_meat: false,
				is_vegan: false,

				recipe_is_active: true,
				// f_recipe_steps: {
				//   recstep_step_num: null,
				//   recstep_step_text: null,
				//   recstep_step_html: null,
				//   recstep_ingredients_csv: null,
				// },
				recstep_step_num: 0,
				sub_steps: [
					{
						recstep_substep_name: "",
						recstep_substep_num: 0,
						steps: [
							{
								recstep_step_text: "",
								recstep_step_html: "",
								recstep_substep_action_id: [],
								f_icons: [],
								ingredients: [],
							},
						],
					},
				],
			},
			required,
		};
	},
	computed: {
		filteredIngredients() {
			return this.$store.getters["food/getAllIngredientMain"].filter((ing) => {
				for (const recipe_ing of this.recipe_ingredients) {
					if (recipe_ing.ingredient_id === ing.ingredient_id) return true;
				}

				return false;
			});
		},
		FILESURL() {
			return process.env.VUE_APP_IMAGEURL;
			// return process.env.VUE_APP_BASEURL;
		},
	},
	methods: {
		getFoodIcons(
			recstep_substep_action_id,
			substep_index,
			step_index,
			sub_steps,
			type
		) {
			// console.log(
			//   "Priyanshu Singh=>",
			//   substep_index,
			//   step_index,
			//   sub_steps[substep_index].steps[step_index].f_icons
			// );
			if (!this.form.recipe_id || type == "change") {
				this.form.sub_steps[substep_index].steps[step_index].f_icons = [];
			}
			// sub_steps[substep_index].steps[step_index].f_icons = [];
			// console.log(recstep_substep_action_id);
			let payload = {
				action_id: recstep_substep_action_id,
				// action_id: recstep_substep_action_id.join(",")
			};
			// console.log(payload);
			this.$store.dispatch("food/setFoodIcons", payload);

			// console.log(
			//   "Jio Data=>",
			//   sub_steps[substep_index].steps[step_index].f_icons
			// );

			// if (this.$store.getters["food/getAllFoodIcons"].length > 0) {
			//   console.log(this.$store.getters["food/getAllFoodIcons"]);
			//   if (response.data.data.sub_steps.length > 0) {
			//     response.data.data.sub_steps.forEach((val, key) => {
			//       val.steps.forEach((val1, key1) => {
			//         this.$store.getters["food/getAllFoodIcons"].forEach(
			//           (i_val, i_key) => {
			//             sub_steps[substep_index].steps[step_index].f_icons.forEach(
			//               main_icon => {
			//                 // if(main_icon == i_val.){
			//                 // this.form.sub_steps[substep_index].steps[step_index].f_icons.push();
			//                 // // this.form.sub_steps[substep_index].steps[step_index].f_icons = sub_steps[substep_index].steps[step_index].f_icons;
			//                 // }
			//               }
			//             );
			//           }
			//         );
			//       });
			//     });
			//   }
			// }
			if (this.$store.getters["food/getAllFoodIcons"].length > 0) {
				this.form.sub_steps[substep_index].steps[step_index].f_icons =
					this.form.sub_steps[substep_index].steps[step_index].f_icons;
			}
		},
		AddSubStep() {
			this.form.sub_steps.push({
				recstep_substep_name: "",
				recstep_substep_num: "",

				steps: [
					{
						f_icons: [],
						ingredients: [],
						recstep_step_text: "",
						recstep_step_html: "",
						recstep_substep_action_id: [],
					},
				],
			});
		},
		RemoveSubStep(substep_index) {
			this.form.sub_steps.splice(substep_index, 1);
		},
		AddInstruction(substep_index) {
			this.form.sub_steps[substep_index].steps.push({
				f_icons: [],
				ingredients: [],
				recstep_step_text: "",
				recstep_step_html: "",
				recstep_substep_action_id: [],
			});
		},
		RemoveInstruction(substep_index, step_index) {
			this.form.sub_steps[substep_index].steps.splice(step_index, 1);
		},
		AddInstructionIngredient(substep_index, step_index) {
			let obj = {
				unit_id: "",
				ingredient_id: "",
				units_quantity: "",
			};

			this.form.sub_steps[substep_index].steps[step_index].ingredients.push(
				obj
			);

			this.$forceUpdate();
		},
		removeInstructionIngredient(substep_index, step_index, ins_ing_index) {
			this.form.sub_steps[substep_index].steps[step_index].ingredients.splice(
				ins_ing_index,
				1
			);
		},
		AddIngredient() {
			let obj = {
				unit_id: "",
				ingredient_id: "",
				units_quantity: "",
			};
			this.recipe_ingredients.push(obj);
		},
		removeIngredient(index) {
			this.recipe_ingredients.splice(index, 1);
		},
		saveRecipeMain() {
			this.$refs.recipeMainValidation.validate().then(async (success) => {
				if (success) {
					try {
						this.form.recipe_ingredients = this.recipe_ingredients;
						this.form.is_meat = this.form.is_meat == true ? 1 : 0;
						this.form.is_red_meat = this.form.is_red_meat == true ? 1 : 0;
						this.form.is_other_meat = this.form.is_other_meat == true ? 1 : 0;
						this.form.is_vegan = this.form.is_vegan == true ? 1 : 0;
						const recipeMainMaster = await FoodServices.saveRecipeMain(
							this.form
						);

						if (recipeMainMaster.data.status) {
							this.$toast({
								component: ToastificationContentVue,
								props: {
									title:
										recipeMainMaster.data.message || "Recipe Added Successfull",
									icon: "EditIcon",
									variant: "success",
								},
							});
							this.$router.replace("/food/recipe-main-list");
						} else {
							this.$toast({
								component: ToastificationContentVue,
								props: {
									title: recipeMainMaster.data.message || "Cannot Added Recipe",
									icon: "EditIcon",
									variant: "failure",
								},
							});
						}
					} catch (error) {
						console.log("Error in saveRecipe ", error);
						this.$toast({
							component: ToastificationContentVue,
							props: {
								title: error.data.message || "Error in saveRecipe",
								icon: "EditIcon",
								variant: "failure",
							},
						});
					}
				} else {
					this.$toast({
						component: ToastificationContentVue,
						props: {
							title: "Please fill mandatory feilds",
							icon: "EditIcon",
							variant: "failure",
						},
					});
				}
			});
		},
		async getSingleRecipeMain(recipe_id) {
			try {
				const response = await FoodServices.getSingleRecipeMain(recipe_id);
				if (response.data.status) {
					// console.log("response.data.data=>", response.data.data);
					if (response.data.data.sub_steps.length > 0) {
						response.data.data.sub_steps.forEach((val, key) => {
							val.steps.forEach((val1, key1) => {
								// alert(val1.recstep_substep_action_id)
								this.getFoodIcons(
									val1.recstep_substep_action_id,
									key,
									key1,
									response.data.data.sub_steps,
									"edit"
								);
							});
						});
					}
					this.form = {
						...this.form,
						...response.data.data,
					};

					this.form.is_meat = this.form.is_meat == 1 ? true : false;
					this.form.is_red_meat = this.form.is_red_meat == 1 ? true : false;
					this.form.is_other_meat = this.form.is_other_meat == 1 ? true : false;
					this.form.is_vegan = this.form.is_vegan == 1 ? true : false;

					// console.log("Happy SIngh=>,", this.form.sub_steps);
					this.recipe_ingredients = response.data.data.recipe_ingredients;
				}
			} catch (err) {
				console.log("Error in getting recipe main details ", err);
			}
		},
	},
	async beforeMount() {
		this.$store.dispatch("food/setFoodActions");
		this.$store.dispatch("food/setRecipeMaster");
		this.$store.dispatch("masters/setLanguage");
		this.$store.dispatch("masters/setCountry");
		this.$store.dispatch("food/setIngredientMain");
		this.$store.dispatch("masters/setLangUnit");
		// this.$store.dispatch("food/setFoodIcons");

		if (this.$route.params.recipe_id && !isNaN(this.$route.params.recipe_id)) {
			this.form.recipe_id = this.$route.params.recipe_id;
			// console.log(
			//   "AvtionsArr=>",
			//   this.$store.getters["food/getAllFoodActions"]
			// );
			this.getSingleRecipeMain(this.form.recipe_id);
		}
	},
};
</script>

<style>
.plusicon {
	display: flex;
	justify-content: space-between;
}
</style>
